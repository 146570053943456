import { LogisticsSystemType } from "@etechinc/smartview-core";

// Web URL Builders
// This file contains functions to generate links to various parts of the application.

//------------------------------
//  Area
//------------------------------

export const linkToArea = (jobNumber?: string, areaId?: string) => `/map/${jobNumber}/area/${areaId}`;

export const linkToAreaTopCategories = (jobNumber?: string, areaId?: string) =>
  `/map/${jobNumber}/area/${areaId}/categories/top`;

export const linkToAreaTopCustomers = (jobNumber?: string, areaId?: string) =>
  `/map/${jobNumber}/area/${areaId}/customers/top`;

export const linkToAreaTopMessages = (jobNumber?: string, areaId?: string) =>
  `/map/${jobNumber}/area/${areaId}/messages/top`;

//------------------------------
//  Barcode
//------------------------------

export const linkToBarcode = (jobNumber?: string, barcodeId?: number) => `/map/${jobNumber}/barcode/${barcodeId}`;

export const linkToBarcodes = (jobNumber?: string) => `/map/${jobNumber}/barcode`;

//------------------------------
//  Batch
//------------------------------

export const linkToSlxBatch = (jobNumber?: string, batchId?: string) => `/map/${jobNumber}/batch/${batchId}`;

export const linkToMonorailBatch = (jobNumber?: string, batchId?: number) =>
  `/map/${jobNumber}/batch/monorail/${batchId}`;

export const linkToBatchInventoryAge = (jobNumber?: string) => `/map/${jobNumber}/batch/inventory/age`;

export const linkToBatchInventoryWeight = (jobNumbr?: string) => `/map/${jobNumbr}/batch/inventory/weight`;

//------------------------------
//  Category
//------------------------------

export const linkToCategories = (jobNumber?: string) => `/map/${jobNumber}/categories`;

export const linkToCategory = (jobNumber?: string, categoryId?: string) => `/map/${jobNumber}/category/${categoryId}`;

//------------------------------
//  Customer
//------------------------------

export const linkToCustomers = (jobNumber?: string) => `/map/${jobNumber}/customers`;

export const linkToCustomer = (jobNumber?: string, customerId?: string) => `/map/${jobNumber}/customer/${customerId}`;

//------------------------------
//  Device
//------------------------------

export const linkToDevice = (jobNumber?: string, ioPointId?: number) => `/map/${jobNumber}/device/${ioPointId}`;

//------------------------------
//  Facility
//------------------------------

export const linkToFacilities = (jobNumber?: string) => `/map/${jobNumber}/facilities`;

export const linkToFacilityActiveAlarms = (jobNumber?: string) => `/map/${jobNumber}/alarms/active`;

export const linkToFacilityActiveMessages = (jobNumber?: string) => `/map/${jobNumber}/messages/active`;

export const linkToFacilityLatestAlarms = (jobNumber?: string, limit: number = 100, offset: number = 0) =>
  `/map/${jobNumber}/alarms/latest?limit=${limit}&offset=${offset}`;

export const linkToFacilityLatestMessages = (jobNumber?: string, limit: number = 100, offset: number = 0) =>
  `/map/${jobNumber}/messages/latest?limit=${limit}&offset=${offset}`;

export const linkToFacilityMessages = (jobNumber?: string) => `/map/${jobNumber}/messages`;

export const linkToFacilityTopAlarms = (jobNumber?: string) => `/map/${jobNumber}/alarms/top`;

export const linkToFacilityTopMessages = (jobNumber?: string) => `/map/${jobNumber}/messages/top`;

export const linkToFacilityTopAlarmLocations = (jobNumber?: string) => `/map/${jobNumber}/alarms/locations`;

export const linkToFacilityTopMessageLocations = (jobNumber?: string) => `/map/${jobNumber}/messages/locations`;

//------------------------------
//  Hub
//------------------------------

export const linkToHubs = (jobNumber?: string) => `/map/${jobNumber}/hubs`;

export const linkToStatus = () => `/status`;

//------------------------------
//  Inventory
//------------------------------

export const linkToInventory = (jobNumber?: string) => `/map/${jobNumber}/inventory`;

//------------------------------
//  Job
//------------------------------

export const linkToJob = (jobNumber?: string) => `/map/${jobNumber}`;
export const linkToJobs = (jobNumber?: string) => `/map/${jobNumber}/jobs`;

//------------------------------
//  Laundry
//------------------------------

export const linkToLaundry = (jobNumber?: string) => `/map/${jobNumber}/laundry`;

export const linkToLaundryTopCategories = (jobNumber?: string) => `/map/${jobNumber}/laundry/categories/top`;

export const linkToLaundryTopCustomers = (jobNumber?: string) => `/map/${jobNumber}/laundry/customers/top`;

//------------------------------
//  Machines
//------------------------------

export const linkToMachines = (jobNumber?: string) => `/map/${jobNumber}/machines`;

//------------------------------
//  Monitoring
//------------------------------

export const linkToMonitoring = (jobNumber?: string) => `/map/${jobNumber}/monitoring/laundry`;

export const linkToMonitoringTag = (jobNumber?: string, tagId?: string) => `/map/${jobNumber}/monitoring/${tagId}`;

export const linkToMonitoringActiveAlarms = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/alarms/active`;

export const linkToMonitoringActiveMessages = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/messages/active`;

export const linkToMonitoringTopAlarmLocations = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/alarms/locations`;

export const linkToMonitoringTopCategories = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/categories/top`;

export const linkToMonitoringTopCustomers = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/customers/top`;

export const linkToMonitoringTopAlarms = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/alarms/top`;

export const linkToMonitoringTopMessages = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/messages/top`;

export const linkToMonitoringTopMessageLocations = (jobNumber?: string, tagId?: string) =>
  `/map/${jobNumber}/monitoring/${tagId}/messages/locations`;

export const linkToMonitoringLatestAlarms = (
  jobNumber?: string,
  tagId?: string,
  limit: number = 100,
  offset: number = 0,
) => `/map/${jobNumber}/monitoring/${tagId}/alarms/latest?limit=${limit}&offset=${offset}`;

export const linkToMonitoringLatestMessages = (
  jobNumber?: string,
  tagId?: string,
  limit: number = 100,
  offset: number = 0,
) => `/map/${jobNumber}/monitoring/${tagId}/messages/latest?limit=${limit}&offset=${offset}`;

//------------------------------
//  Producer
//------------------------------

export const linkToProducer = (jobNumber?: string, producerId?: string) => `/map/${jobNumber}/producer/${producerId}`;

export const linkToProducerTopCustomers = (jobNumber?: string, producerId?: string) =>
  `/map/${jobNumber}/producer/${producerId}/customers/top`;

export const linkToProducerTopCategories = (jobNumber?: string, producerId?: string) =>
  `/map/${jobNumber}/producer/${producerId}/categories/top`;

export const linkToProducerTopMessages = (jobNumber?: string, producerId?: string) =>
  `/map/${jobNumber}/producer/${producerId}/messages/top`;

//------------------------------
//  Producer View
//------------------------------

export const linkToProducerView = (jobNumber?: string, producerViewId?: string) =>
  `/map/${jobNumber}/producer-view/${producerViewId}`;

export const linkToProducerViewTopCategories = (jobNumber?: string, producerViewId?: string) =>
  `/map/${jobNumber}/producer-view/${producerViewId}/categories/top`;

export const linkToProducerViewTopCustomers = (jobNumber?: string, producerViewId?: string) =>
  `/map/${jobNumber}/producer-view/${producerViewId}/customers/top`;

export const linkToProducerViewTopMessages = (jobNumber?: string, producerViewId?: string) =>
  `/map/${jobNumber}/producer-view/${producerViewId}/messages/top`;

//------------------------------
//  Rail
//------------------------------

export const linkToRails = (jobNumber?: string) => `/map/${jobNumber}/rail`;

export const linkToRail = (jobNumber?: string, railId?: number) => `/map/${jobNumber}/rail/${railId}`;

export const linkToRailActiveAlarms = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/alarms/active`;

export const linkToRailActiveMessages = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/messages/active`;

export const linkToRailBatchHistory = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/batch/history`;

export const linkToRailLatestAlarms = (
  jobNumber?: string,
  railId?: number,
  limit: number = 100,
  offset: number = 0,
) => `/map/${jobNumber}/rail/${railId}/alarms/latest?limit=${limit}&offset=${offset}`;

export const linkToRailLatestMessages = (
  jobNumber?: string,
  railId?: number,
  limit: number = 100,
  offset: number = 0,
) => `/map/${jobNumber}/rail/${railId}/messages/latest?limit=${limit}&offset=${offset}`;

export const linkToRailTopAlarms = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/alarms/top`;

export const linkToRailTopCategories = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/categories/top`;

export const linkToRailTopCustomers = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/customers/top`;

export const linkToRailTopMessages = (jobNumber?: string, railId?: number) =>
  `/map/${jobNumber}/rail/${railId}/messages/top`;

//------------------------------
//  Refs
//------------------------------

export const linkToRef = (jobNumber?: string, refId?: string, type?: LogisticsSystemType) => {
  switch (type) {
    case LogisticsSystemType.SLX:
      return `/map/${jobNumber}/producer/${refId}`;
    case LogisticsSystemType.MONORAIL:
      return `/map/${jobNumber}/rail/${refId}`;
    default:
      return "#";
  }
};

//------------------------------
//  Settings
//------------------------------

export const linkToSettings = (jobNumber?: string) => `/map/${jobNumber}/settings`;
